import * as React from 'react';

import { ScPage } from '../components/page/styled';
import { ParallaxCards } from '../components/ParallaxCards';
import { MainLayout } from '../layouts/main-layout';
import { Hero } from '../sections/Home/Hero';
import { HowDoesThatWork } from '../sections/Home/HowDoesThatWork';
import { OurPromiseToYou } from '../sections/Home/OurPromiseToYou';
import { ReadyToStartSaving } from '../sections/Home/ReadyToStartSaving';
import { WeHelp } from '../sections/Home/WeHelp';
import {SEO} from "../components/Seo/Seo";

export default function Index() {
	return (
		<MainLayout>
			<ScPage disableOverflowHidden>
				<Hero />
				<WeHelp />
				<HowDoesThatWork />
				<ParallaxCards />
				<OurPromiseToYou />
				<ReadyToStartSaving />
			</ScPage>
		</MainLayout>
	);
}

export const Head = () => (
	<SEO
		title="Maximise Savings with Salary Sacrifice | Easy Setup & Application"
		description="Gogeta offers impactful salary sacrifice benefits that are easy for employers to implement, helping maximise savings for both employers and employees."
	/>
)
