import React from 'react';
import { motion } from 'framer-motion';

import { MotionDiv } from '../../animations/components';
import {
	animationContainer,
	cardUp,
	fadeInLeft,
	fadeInRight,
	fadeInUp,
} from '../../animations/variants';
import image1 from '../../assets/images/promise-1.png';
import image2 from '../../assets/images/promise-2.png';
import image3 from '../../assets/images/promise-3.png';
import { ScContainer } from '../../components/container/styled';
import { ImageTextCard } from '../../components/ImageTextCard';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';
import useWindowDimensions from '../../hooks/use-window-width';

import { ScOurPromiseToYouItemsWrapper, ScTestimonialsWrapper } from './styled';

const items = [
	{
		title: 'We save<br/>you money',
		text: 'Can’t say plainer than that. We can help you make the most of your salary.',
		image: image1,
	},
	{
		title: 'We make<br/>it easy',
		text: 'A few clicks and you’re all set. We believe more people should be able to access these brilliant benefits, so we’ve made it as simple as possible.',
		image: image2,
	},
	{
		title: 'We’re fair and<br/>transparent',
		text: 'We want everyone – from employees to employers to partners – to feel good when they use Gogeta. There’s no cost to employers, and nurseries and bike shops benefit too.',
		image: image3,
	},
];

export const OurPromiseToYou = () => {
	const { ref, controls } = useContentAnimation();
	const { width } = useWindowDimensions();
	return (
		<ScContainer>
			<motion.section initial="hidden" animate={controls} variants={animationContainer}>
				<ScTestimonialsWrapper isLargeTop>
					<MotionDiv variants={fadeInUp}>
						<ScTitle centered>Our promises to you</ScTitle>
					</MotionDiv>
					<ScOurPromiseToYouItemsWrapper ref={ref}>
						{items.map((item, index) => (
							<MotionDiv
								key={item.title}
								variants={width >= 1220 ? cardUp : index % 2 === 0 ? fadeInLeft : fadeInRight}
							>
								<ImageTextCard item={item} />
							</MotionDiv>
						))}
					</ScOurPromiseToYouItemsWrapper>
				</ScTestimonialsWrapper>
			</motion.section>
		</ScContainer>
	);
};
