import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from 'styled-components';

import { MotionDiv } from '../../animations/components';
import { animationContainer, fadeInUp } from '../../animations/variants';
import Bcorp from '../../assets/icons/b-corp.png';
import IsoIcon from '../../assets/icons/iso-icon.png';
import { ScContainer } from '../../components/container/styled';
import { ScParagraph } from '../../components/Paragraph/styled';
import { ScTitle } from '../../components/Title/styled';
import useContentAnimation from '../../hooks/use-content-animation';

import { ScWeHelpContainer, ScWeHelpIcons, ScWeHelpWrapper } from './styled';

export const WeHelp = () => {
	const theme = useTheme();
	const { ref, controls } = useContentAnimation();

	return (
		<ScWeHelpWrapper>
			<ScContainer>
				<motion.div initial="hidden" animate={controls} variants={animationContainer}>
					<ScWeHelpContainer>
						<MotionDiv variants={fadeInUp}>
							<ScTitle centered color={theme.colors.darkText}>
								We help make salaries go further
							</ScTitle>
						</MotionDiv>
						<MotionDiv variants={fadeInUp}>
							<ScParagraph centered color={theme.colors.darkText}>
								<p>Everyone’s salary deserves a bit of oomph.</p>
								<p>
									With Gogeta you can pay for things like nursery bills, household appliances or a
									new bike before you get taxed.
								</p>
							</ScParagraph>
						</MotionDiv>
						<MotionDiv variants={fadeInUp}>
							<ScWeHelpIcons ref={ref}>
								<img src={IsoIcon} alt={'iso'}/>
								<img src={Bcorp} alt={'bcorp'}/>
							</ScWeHelpIcons>
						</MotionDiv>
					</ScWeHelpContainer>
				</motion.div>
			</ScContainer>
		</ScWeHelpWrapper>
	);
};
